import { Helmet } from "react-helmet"
import Header from "../components/Header"
import PageTransitionAnimation from "../components/Animations/PageTransitionAnimation"
import VerticalTimeline from "../components/VerticalTimeline"
import SectionTitle from "../components/Titles/SectionTitle"



const SetsPage = () => {
  return (
    <PageTransitionAnimation>
      <Helmet>
        <title>DJ Sets</title>
        <meta
          name="description"
          content="Dj George Woofer Sets"
        />
        <meta
          name="keywords"
          content="Dj, George Woofer, George, Woofer, Music, Audio, Dance, Party, Events"
        />
      </Helmet>
      <Header title="SETS" />

      <section>
        <SectionTitle title="EXPERIENCE THE ENERGY" />       
          <VerticalTimeline />
      </section>



    </PageTransitionAnimation>
  )
}

export default SetsPage