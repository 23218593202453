import { useEffect, useState } from "react"
import { AiOutlineArrowUp } from "react-icons/ai"

import { motion, useScroll, useTransform } from "framer-motion"


const GoToTopButton = () => {

  const [isVisible, setIsVisible] = useState(false)
  const { scrollYProgress } = useScroll()

  // const scale = useTransform(scrollYProgress, [0, 1], [1, 2]);

  // Show button when page is scrolled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  // Set the top coordinate to 0 
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility)

    return () => {
      window.removeEventListener("scroll", toggleVisibility)
    }
  }, [])



  return (
    <div className="c-go-to-top" style={{ display: isVisible ? "flex" : "none" }}>

      <svg id="progress" width="100" height="100" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="30" pathLength="1" className="bg" />
        <motion.circle
          cx="50"
          cy="50"
          r="30"
          pathLength="1"
          className="indicator"
          style={{ pathLength: scrollYProgress }}
        />
      </svg>

      <AiOutlineArrowUp
        className="scrollTop c-go-to-top"
        onClick={scrollToTop}
      />
    </div>
  )
}

export default GoToTopButton