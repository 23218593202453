import { useState, useEffect } from "react"
import Preloader from "../Preloader"

const FetchGallery = () => {

    const [mainData, setMainData] = useState([])

    const data = async () => {
        const res = await fetch("https://dj.george-woofer.com/wp-json/wp/v2/gallery_image?acf_format=standard")
        const results = await res.json()
        const finalResults = results[0].acf.image_gallery_images

        console.log(finalResults)

        // const sortedTracks = [...results].sort((a, b) => a.id - b.id)
        console.log("THE RESULTS ARE " + finalResults.length)
        setMainData(finalResults)
    }

    useEffect(() => {
        data()
    }, [])


    const imageLightbox = (e) => {
        console.log(e.target.src)
    }

    return (
        <>
            {mainData?.map((photo, index) => {
                return <img
                    key={index}
                    src={photo.sizes.large}
                    alt="woofer"
                    className="mt-[20px] lg:mt-[0px] rounded"
                    onClick={imageLightbox}
                />
            })}
        </>
    )
}

export default FetchGallery