import MagneticEffect from "../MagneticEffect"
import CustomNavigationLinkAnimation from "../Animations/CustomNavigationLinkAnimation"
import Logo from "../../assets/images/logo.png"
import {
  PiHouseThin,
  BiLogoSoundcloud,
  FaFacebookF,
  FaInstagram,
  SiAboutdotme,
  SiPioneerdj,
  BsEnvelopeAt,
  BsTiktok
} from "../../assets/icons"


const Footer = () => {

  const socialIconWidth = "30px"
  const textSize = "300px"
  const iconPadding = "0px"

  return (
    <footer className="w-screen bg-[#0000006f]">
      <div className="max-w-[60%] flex flex-col justify-center items-center py-10 mx-auto">

        <div className="c-footer-log w-[150px]">
          <img src={Logo} alt="woofer" />
        </div>


        <div className="c-footer-log mt-[30px]">
          <p className="text-[20px] text-center">Copyright © 2023 George Woofer</p>
        </div>
        <div className="c-footer-social-media-links flex justify-center items-center mt-[30px]">

          <CustomNavigationLinkAnimation delay={.2}>
            <MagneticEffect>
              <a href="https://soundcloud.com/user-999969115" target="_blank" rel="noopener noreferrer">
                <BiLogoSoundcloud size={`${socialIconWidth}`} className={`text-[${textSize}] transition ease-in-out cursor-pointer rounded-full border-[2px] hover:scale-[1.5] hover:text-[#205256] py-[1px] mr-[20px]`} />
              </a>
            </MagneticEffect>
          </CustomNavigationLinkAnimation>

          <CustomNavigationLinkAnimation delay={.4}>
            <MagneticEffect>
              <a href="https://www.facebook.com/george.woofer" target="_blank" rel="noopener noreferrer">
                <FaFacebookF size={`${socialIconWidth}`} className={`text-[${textSize}] transition ease-in-out cursor-pointer rounded-full border-[2px] hover:scale-[1.5] hover:text-[#205256] py-[3px] mr-[20px]`} />
              </a>
            </MagneticEffect>
          </CustomNavigationLinkAnimation>

          <CustomNavigationLinkAnimation delay={.6}>
            <MagneticEffect>
              <a href="https://www.instagram.com/george_woofer/" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={`${socialIconWidth}`} className={`text-[${textSize}] transition ease-in-out cursor-pointer rounded-full border-[2px] hover:scale-[1.5] hover:text-[#205256] py-[3px] mr-[20px]`} />
              </a>
            </MagneticEffect>
          </CustomNavigationLinkAnimation>

          <CustomNavigationLinkAnimation delay={.8}>
            <MagneticEffect>
              <a href="https://www.tiktok.com/@george_woofer" target="_blank" rel="noopener noreferrer">
                <BsTiktok size={`${socialIconWidth}`} className={`text-[${textSize}] transition ease-in-out cursor-pointer rounded-full border-[2px] hover:scale-[1.5] hover:text-[#205256] py-[3px]`} />
              </a>
            </MagneticEffect>
          </CustomNavigationLinkAnimation>

        </div>

      </div>
    </footer>
  )
}

export default Footer