import { useQuery } from "@tanstack/react-query"
import ReactPlayer from "react-player"
import Preloader from "../Preloader"

const FetchPopularSets = () => {

    // const { data } = useQuery(["wooferpopularlists"], () => {
    //     return fetch("https://dj.george-woofer.com/wp-json/wp/v2/popular-set?acf_format=standard")
    //         .then(res => res.json())
    // })

    const fetchWooferPopularSetsQuery = async () => {
        const res = await fetch("https://dj.george-woofer.com/wp-json/wp/v2/popular-set?acf_format=standard")
        const data = await res.json()
        const sortedTracks = [...data].sort((a, b) => a.id - b.id)
        return sortedTracks
    }

    const { data, isLoading, error, isFetching } = useQuery(
        ["fetchWooferGalleryQuery"],
        fetchWooferPopularSetsQuery,
        // {
        //     staleTime: 1000 * 60 * 10,
        //     cacheTime: 1000 * 60 * 60
        // }
    )

    if (isLoading) {
        return <Preloader />
    }
    if (error) {
        return <h1 className="text-[100px] text-[red]"> IS ERRORRRRRR....</h1>
    }

    if (isFetching) {
        return <Preloader />
    }

    let count = 0

    return (
        <>
            {data?.map((set, index) => {
                if (count >= 3) return
                count++
                console.log(data)
                return <div><h2 className="text-[20px] text-center my-[20px] leading-[24px] min-h-[80px] flex justify-center items-center">{set.title.rendered}</h2><ReactPlayer
                    url={set.acf.sound_cloud_player_url}
                    width="100%"
                    height="100%"
                    playing={false}
                    controls={true}
                /></div>
            })}
        </>
    )
}

// {popularSetsVar.map((widget, index) => {
//     if (count >= 8) return
//     count++
//     return (
//       <ElementAnimation key={index}>
//         <h3 className="c-homepage-widget-title-v1">{widget.title.rendered}</h3>

//         <ReactPlayer
//           url={widget.acf.sound_cloud_player_url}
//           width="100%"
//           height="400px"
//           playing={false}
//           controls={true}
//         />
//       </ElementAnimation>
//     )
//   })}
export default FetchPopularSets