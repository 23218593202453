import React from "react"
import { useState, useEffect, createContext } from "react"
import {QueryClient, QueryClientProvider} from "@tanstack/react-query"


import MainNavigation from "./components/Navigation"
import AnimatedLogo from "./components/AnimatedLogo"
import GoToTopButton from "./components/GoToTopButton"
import Footer from "./components/Footer/"
import Preloader from "./components/Preloader"
import ScrollProgress from "./components/ScrollProgress"

import ReactPlayer from "react-player"

export const ThemeContext = createContext(null)

function App() {

  const client = new QueryClient()

  const [isLoading, setIsLoading] = useState(false)

  const [theme, setTheme] = useState("dark")

  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"))
  }

  useEffect(() => {

    setIsLoading(true)

    setTimeout(() => {
      setIsLoading(false)
    }, 1000)

  }, [])

  if (isLoading) {
    return <Preloader />
  }

  return (
    <React.StrictMode>
      <QueryClientProvider client={client}>
      <ThemeContext.Provider value={{ theme, toggleTheme }}>
          <div className="App" id={theme}>

            {/* <ScrollProgress /> */}
            <GoToTopButton />
            <MainNavigation />
            <Footer />
          
          </div>
      </ThemeContext.Provider>
      </QueryClientProvider>
    </React.StrictMode>
  )
}

export default App