import { useQuery } from "@tanstack/react-query"
import Preloader from "../Preloader"

const FetchPlaylists = () => {

    // const { data } = useQuery(["wooferplaylists"], () => {
    //     return fetch("https://dj.george-woofer.com/wp-json/wp/v2/playlist?acf_format=standard")
    //         .then(res => res.json())
    // })

    const fetchWooferPlaylistsQuery = async () => {
        const res = await fetch("https://dj.george-woofer.com/wp-json/wp/v2/playlist?acf_format=standard")
        const data = await res.json()
        return data
    }

    const { data, isLoading, error, isFetching } = useQuery(
        ["fetchWooferPlaylistsQuery"],
        fetchWooferPlaylistsQuery,
        // {
        //     staleTime: 1000 * 60 * 10,
        //     cacheTime: 1000 * 60 * 60
        // }
    )

    if (isLoading) {
        return <Preloader />
    }
    if (error) {
        return <h1 className="text-[100px] text-[red]"> IS ERRORRRRRR....</h1>
    }

    if (isFetching) {
        return <Preloader />
    }

    let count = 0
    return (
        <>
            {data?.map((set, index) => {
                if (count >= 2) return
                count++
                return <div>
                    <h2 className="text-[20px] text-center my-[20px] leading-[24px] min-h-[80px] flex justify-center items-center">
                        {set.title.rendered}
                    </h2>
                    <iframe
                        key={index}
                        className="my-[50px]"
                        title="woofer"
                        width="100%"
                        height="500px"
                        scrolling="no"
                        frameborder="no"
                        allow="autoplay"
                        src={set.acf.playlist_player_url}
                    ></iframe>
                </div>
            })}
        </>
    )
}

export default FetchPlaylists