import { useState, useEffect } from "react"
import { motion } from "framer-motion"
import Logo from "../../assets/images/logo.png"
import WooferFace9 from "../../assets/images/woofer-face-9.png"


const Index = ({ title, span = null }) => {

  const [isLoading, setIsLoading] = useState(false)


  useEffect(() => {
    setTimeout(() => {
      setIsLoading(true)
    }, 2000)
  })

  return (
    <motion.header
      initial={{ y: 50, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.3 }}
      viewport={{ once: true }}
      className="w-full h-screen relative flex lg:flex-row flex-col">
      <div className="c-header-left-container w-full lg:w-1/2 h-full py-20 flex flex-col justify-center items-start flex-1 order-2 lg:order-1">

{/* Scroll down arrow */}
        {/* <div className="mouse absolute top-[400px] left-[200px] border-[3px]"></div> */}

        <motion.img
          initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: .3, delay: .1, 
          // type: "spring",
          // damping: 10,
          // mass: 0.75,
          // stiffness: 100 
        }}
          viewport={{ once: true }}
          src={Logo} alt="woofer" className="c-header-logo w-[150px]" />

        {/* <AnimatedLogoHeader /> */}
        <motion.h1
          initial={{  opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: .3, delay: .2, 
          // type: "spring",
          // damping: 10,
          // mass: 0.75,
          // stiffness: 100 
        }}
          viewport={{ once: true }}
          className="page-title-gradient-v1">{title}<span className="c-span-title">{span}</span></motion.h1>
          </div>

      {/* <LottieScrollDown /> */}

      <div className="c-header-right-container w-full lg:w-1/2 h-full flex-1 p-0 m-0 order-1 lg:order-2 lg:block">
        <motion.img 
          initial={{ opacity: 0 }}
          whileInView={{ opacity: .2 }}
          transition={{ duration: .3, delay: .5}}
          viewport={{ once: true }} src={WooferFace9} className="c-header-right-container-img" alt="woofer" />
      </div>

    </motion.header>
  )
}

export default Index