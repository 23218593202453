import { useState, useEffect, useRef, Suspense } from "react"
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"
import MagneticEffect from "../MagneticEffect"
import Logo from "../../assets/images/logo.png"

import {
  PiHouseThin,
  BiLogoSoundcloud,
  FaFacebookF,
  FaInstagram,
  SiAboutdotme,
  SiPioneerdj,
  BsEnvelopeAt,
  BsTiktok
} from "../../assets/icons"
import CustomNavigationLinkAnimation from "../Animations/CustomNavigationLinkAnimation"
import HomePage from "../../pages/HomePage"
import AboutPage from "../../pages/AboutPage"
import SetsPage from "../../pages/SetsPage"
import ContactPage from "../../pages/ContactPage"
import ErrorPage from "../../pages/ErrorPage"
import Preloader from "../../components/Preloader"

const Index = () => {

  const [showMenu, setShowMenu] = useState(false)

  const menuRef = useRef(null)

  useEffect(() => {

    const handleWindowClick = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    }

    document.addEventListener("mousedown", handleWindowClick)

  })

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  let iconWidth = "60px"
  let socialIconWidth = "25px"
  let textSize = "50px"

  return (
    <Router>
      <nav ref={menuRef} id="c-nav-round" className={showMenu ? "c-nav-round-open" : ""}>
        {/* Toggle Menu */}

        {/* <div className={`${showMenu ? "opacity-[1]" : "opacity-[0]"} w-screen h-screen fixed top-[0px] left-[0px] bg-[#0000009f] transition ease-in-out duration-300 pointer-events-none`}></div> */}

        <img src={Logo} alt="woofer" className="w-[100px]" />

        <span onClick={toggleMenu} className="absolute block left-[200px] top-[0px] w-[100px] h-[100px] text-[20px] cursor-pointer z-50"></span>
        <div className={showMenu ? "mob-menu-active" : ""} id="c-menu-button">
          <span className={showMenu ? "border-[2px]" : "border-[0px]"}></span>
          <span className={showMenu ? "border-[2px]" : "border-[0px]"}></span>
          <span className={showMenu ? "border-[2px]" : "border-[0px]"}></span>
        </div>

        {/* Left Navigation */}
        <div id="c-nav-left-content" className={``}>

          <CustomNavigationLinkAnimation delay={.2}>
            <MagneticEffect>
              <Link to="/" onClick={toggleMenu}><PiHouseThin size={`${iconWidth}`} className={`text-[${textSize}] transition ease-in-out cursor-pointer rounded-full border-[2px] hover:scale-[1.5] hover:text-[#205256] z-10`} /></Link>
            </MagneticEffect>
          </CustomNavigationLinkAnimation>
          <CustomNavigationLinkAnimation delay={.4}>
            <MagneticEffect>
              <Link to="/about" onClick={toggleMenu}><SiAboutdotme size={`${iconWidth}`} className={`text-[${textSize}] transition ease-in-out cursor-pointer rounded-full border-[2px] hover:scale-[1.5] hover:text-[#205256]`} /></Link>
            </MagneticEffect>
          </CustomNavigationLinkAnimation>
          <CustomNavigationLinkAnimation delay={.6}>
            <MagneticEffect>
              <Link to="/sets" onClick={toggleMenu}> <SiPioneerdj size={`${iconWidth}`} className={`text-[${textSize}] transition ease-in-out cursor-pointer rounded-full border-[2px] hover:scale-[1.5] hover:text-[#205256]`} /></Link>
            </MagneticEffect>
          </CustomNavigationLinkAnimation>
          <CustomNavigationLinkAnimation delay={.8}>
            <MagneticEffect>
              <Link to="/contact" onClick={toggleMenu}> <BsEnvelopeAt size={`${iconWidth}`} className={`text-[${textSize}] transition ease-in-out cursor-pointer rounded-full border-[2px] hover:scale-[1.5] hover:text-[#205256]`} /></Link>
            </MagneticEffect>
          </CustomNavigationLinkAnimation>
        </div>


        {/* Right Navigation */}
        <div id="c-nav-social-content" className={``}>
          <CustomNavigationLinkAnimation delay={1.2}>
            <MagneticEffect>
              <a href="https://soundcloud.com/user-999969115" target="_blank" rel="noopener noreferrer">
                <BiLogoSoundcloud size={`${socialIconWidth}`} className={`text-[${textSize}] transition ease-in-out cursor-pointer rounded-full border-[2px] hover:scale-[1.5] hover:text-[#205256] z-10`} />
              </a>
            </MagneticEffect>
          </CustomNavigationLinkAnimation>

          <CustomNavigationLinkAnimation delay={1.4}>
            <MagneticEffect>
              <a href="https://www.facebook.com/george.woofer" target="_blank" rel="noopener noreferrer">
                <FaFacebookF size={`${socialIconWidth}`} className={`text-[${textSize}] transition ease-in-out cursor-pointer rounded-full border-[2px] hover:scale-[1.5] hover:text-[#205256]`} />
              </a>
            </MagneticEffect>
          </CustomNavigationLinkAnimation>
          <CustomNavigationLinkAnimation delay={1.6}>
            <MagneticEffect>
              <a href="https://www.instagram.com/george_woofer/" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={`${socialIconWidth}`} className={`text-[${textSize}] transition ease-in-out cursor-pointer rounded-full border-[2px] hover:scale-[1.5] hover:text-[#205256]`} />
              </a>
            </MagneticEffect>
          </CustomNavigationLinkAnimation>
          <CustomNavigationLinkAnimation delay={1.8}>
            <MagneticEffect>
              <a href="https://www.tiktok.com/@george_woofer" target="_blank" rel="noopener noreferrer">
                <BsTiktok size={`${socialIconWidth}`} className={`text-[${textSize}] transition ease-in-out cursor-pointer rounded-full border-[2px] hover:scale-[1.5] hover:text-[#205256]`} />
              </a>
            </MagneticEffect>
          </CustomNavigationLinkAnimation>
        </div>
      </nav>

      <Suspense fallback={<Preloader />}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/sets" element={<SetsPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Suspense>
    </Router>
  )
}

export default Index