import { useState, useEffect } from "react"
import ReactPlayer from "react-player"
// import Preloader from "../Preloader"

const FetchSets = () => {
    // https://dj.george-woofer.com/wp-json/wp/v2/set?per_page=12
    // https://dj.george-woofer.com/wp-json/wp/v2/set?acf_format=standard
    const [mainData, setMainData] = useState([])

    const data = async () => {
        const res = await fetch(
            "https://dj.george-woofer.com/wp-json/wp/v2/set?per_page=12"
        )
        const results = await res.json()
        const sortedTracks = [...results].sort((a, b) => a.id - b.id)
        setMainData(sortedTracks)
    }

    useEffect(() => {
        data()
    }, [])


    return (
        <>
            {/* {isFetching ? <div>Refreshing...</div> : null} */}
            {mainData?.map((set, index) => {
                return <div key={index}><h2 className="text-[20px] text-center my-[20px] leading-[24px] min-h-[80px] flex justify-center items-center">{set.title.rendered}</h2><ReactPlayer
                    url={set.acf.sound_cloud_url}
                    width="100%"
                    height={"300px"}
                    playing={false}
                    controls={true}
                /></div>
            })}
        </>
    )
}

export default FetchSets