import { Helmet } from "react-helmet"
import Header from "../components/Header"
import ContactForm from "../components/ContactForm"
import PageTransitionAnimation from "../components/Animations/PageTransitionAnimation"




const ContactPage = () => {
  return (
    <PageTransitionAnimation>
      <Helmet>
        <title>Contact</title>
        <meta
          name="description"
          content="Dj George Woofer Contact"
        />
        <meta
          name="keywords"
          content="Dj, George Woofer, George, Woofer, Music, Audio, Dance, Party, Events"
        />
      </Helmet>

      <Header title="CONTACT" />
      

      <section className="c-contact-page-section mb-[300px]">
        <ContactForm />
      </section>

    </PageTransitionAnimation>
  )
}

export default ContactPage