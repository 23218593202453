import { Helmet } from "react-helmet"
import Header from "../components/Header"
import SectionTitle from "../components/Titles/SectionTitle"
import PageTransitionAnimation from "../components/Animations/PageTransitionAnimation"
import FetchGallery from "../components/FetchData/FetchGallery"

// const VerticalTimeline = lazy(() => import("../components/VerticalTimeline"))

// import FetchSetsProVersion from "../components/FetchData/FetchSetsProVersion"

const AboutPage = () => {

  return (
    <PageTransitionAnimation>
      <Helmet>
        <title>Biography</title>
        <meta
          name="description"
          content="Dj George Woofer About"
        />
        <meta
          name="keywords"
          content="Dj, George Woofer, George, Woofer, Music, Audio, Dance, Party, Events"
        />
      </Helmet>
      
      <div className="c-about-page-section">
        <Header title="BIO..." />
        <section className="w-5/5 md:w-4/5 mx-auto">
          <p className="text-[20px] md:text-[22px] mt-[50px]">Since I was 12 years old when I first bought my turntables and DJing has been a passion of mine. I fell in love with mixing tracks and scratching records from the very start. Though I have spent countless hours honing my skills as a DJ over the years, I have no desire to pursue it as a career. For me, DJing has always been about following my heart and creative spirit, not about making money or becoming famous. When I stand behind the decks, I get lost in the music and my sole intention is to spread joy, build community, and encourage people to let go of their worries, even if just for a few songs. I achieve immense satisfaction when I look out at a crowd and see smiles on faces as they dance together in harmony. At the end of the day, connecting people through music and conjuring positive vibes of love and enjoyment is what DJing is all about for me. I don't need flashing lights or huge crowds - just good people, great tracks, and the ability to share my passion. This is the magical feeling I have sought since I first cued up a record decades ago. My heart is full of gratitude for the privilege of sharing the gift of music, wherever and whenever I perform. Love you All. ❤️❤️❤️"</p>
        </section>
      </div>

     
      <section className="w-full">
        <SectionTitle title="EXPERIENCES" />
        <div className="c-iframe-player mt-[50px] pb-[200px] grid grid-cols-2 gap-4 md:grid-cols-2 lg:grid-cols-4">
          <FetchGallery />
        </div>
      </section>

    </PageTransitionAnimation>
  )
}

export default AboutPage