import { Helmet } from "react-helmet"
import Header from "../components/Header"
import PageTransitionAnimation from "../components/Animations/PageTransitionAnimation"
// import VerticalTimeline from "../components/VerticalTimeline"

import SectionTitle from "../components/Titles/SectionTitle"
import DjNameAnimation from "../components/DjNameAnimation"
import ElementAnimation from "../components/Animations/ElementAnimation"

import FetchSets from "../components/FetchData/FetchSets"
import FetchPopularSets from "../components/FetchData/FetchPopularSets"
import FetchPlaylists from "../components/FetchData/FetchPlaylists"

import HorizontalScroll from "../components/HorizontalScroll"


const HomePage = () => {

  return (
    <PageTransitionAnimation>
      <Helmet>
        <title>HOME</title>
        <meta
          name="description"
          content="Dj George Woofer"
        />
        <meta
          name="keywords"
          content="Dj, George Woofer, George, Woofer, Music, Audio, Dance, Party, Events"
        />
      </Helmet>

      {/* <Helmet>
        <title>Music Website</title>
        <meta
          name="description"
          content="Get stats about every music from every movie"
        />
        <meta
          name="keywords"
          content="Music, Audio, Lyrics"
        />
      </Helmet> */}

      <Header title="GEORGE WOOFER" span="MUSIC" />

      {/* <section className="w-full">
      <SectionTitle title="NEW SECTION" />
        <HorizontalScroll />
      </section> */}

      <section className="w-full">
        <SectionTitle title="POPULAR SETS" />
        <ElementAnimation delay={.2}>
          <div className="c-iframe-player grid grid-cols-1 gap-4 lg:grid-cols-3">
            <FetchPopularSets />
          </div>
        </ElementAnimation>
      </section>

      <div className="w-full hidden lg:flex">
        <DjNameAnimation />
      </div>

      <section className="full">
        <SectionTitle title="PLAYLISTS" />
        <ElementAnimation delay={.2}>
          <div className="c-iframe-player grid grid-cols-1 gap-4 lg:grid-cols-2">
            <FetchPlaylists />
          </div>
        </ElementAnimation>
      </section>

      <section className="w-full">
        <SectionTitle title="SETS" />
        <ElementAnimation delay={.2}>
          <div className="c-iframe-player grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
            <FetchSets />
          </div>
        </ElementAnimation>
      </section>

    </PageTransitionAnimation>
  )
}

export default HomePage