import { motion } from "framer-motion";

const ElementAnimation = ({ children, delay = 0 }) => {
  return (
    <motion.div
      initial={{ y: 50, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.3, delay }}
      viewport={{ once: true }}
    >
      {children}
    </motion.div>
  )
}

export default ElementAnimation