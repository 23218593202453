import { motion, useTransform, useScroll } from "framer-motion";

const DjNameAnimation = () => {

    // const scrollInfo = useScroll();
    // const scrollYProgress = scrollInfo.scrollYProgress;

    const { scrollYProgress } = useScroll();
    const x = useTransform(scrollYProgress, [0, 1], [-500, 1300])
    const xx = useTransform(scrollYProgress, [0, 1], [-800, -50])
    const xxx = useTransform(scrollYProgress, [0, 1], [-400, 200])

    return (
        <div className="hidden lg:flex w-full h-screen flex-col justify-evenly items-center overflow-hidden">
            <motion.h2
                style={{ x }}
                transition={{ type: "spring", stiffness: 150, damping: 15, mass: 0.1 }}
                className="w-full py-10 relative text-[200px] text-[#0000005c] whitespace-nowrap"
            >
                WOOFER GEORGE WOOFER GEORGE WOOFER
            </motion.h2>

            <motion.h2
                style={{ x: xx }}
                transition={{ type: "spring", stiffness: 150, damping: 15, mass: 0.1 }}
                className="w-full py-10 relative  text-[200px] text-[#ffffff30] whitespace-nowrap"
            >
                LOVE MUSIC LOVER MUSIC LOVE MUSCI
            </motion.h2>

            <motion.h2
                style={{ x: xxx }}
                transition={{ type: "spring", stiffness: 150, damping: 15, mass: 0.1 }}
                className="w-full py-10 relative  text-[200px] text-[#00000073] whitespace-nowrap"
            >
                SOUND DANCE HAPPY SOUND DANCE HAPPY SOUND DANCE HAPPY
            </motion.h2>

        </div>
    )
}

export default DjNameAnimation