import { useState, useEffect } from "react"
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component"
import "react-vertical-timeline-component/style.min.css"
import { GiCompactDisc } from "react-icons/gi"
import ReactPlayer from "react-player"

import Preloader from "../Preloader"

const Index = () => {

    // Load more sets
    // const imagePerRow = 12

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    // let [next, setNext] = useState(imagePerRow)

    // const handleMoreSets = () => {
    //     setNext(next + imagePerRow)
    //     console.log(next)
    // }

    useEffect(() => {
        const getData = async () => {
            // const res = await fetch(`https://dj.george-woofer.com/wp-json/wp/v2/set?per_page=${next}`)
            const res = await fetch(`https://dj.george-woofer.com/wp-json/wp/v2/set?per_page=35`)
            const results = await res.json()
            const reversedData = [...results].reverse()
            // const sortedTracks = [...results].sort((a, b) => a.id - b.id)
            // console.log("SETS RESPONSE = " + sortedTracks)
            setData(reversedData)
        }

        getData()

    }, [])


    return (
        <div className="py-[50px]">
            <VerticalTimeline>
                {data.map((track, index) => {
                    return <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
                        contentArrowStyle={{ borderRight: "7px solid  #ffffff" }}
                        date={track.acf.date}
                        iconStyle={{ background: "rgb(33, 150, 243)", color: "#fff" }}
                        icon={<GiCompactDisc />}
                        key={index}
                    >
                        {isLoading ?
                            <div className="test-container">
                                <h3 className="vertical-timeline-element-title">{track.title.rendered}</h3>
                                {/* <h4 className="vertical-timeline-element-subtitle relative top-[10px]"></h4> */}
                                <div className="mt-[30px]">
                                    <ReactPlayer
                                        url={track.acf.sound_cloud_url}
                                        width="100%"
                                        height="100%"
                                        playing={false}
                                        controls={true}
                                    />
                                </div>
                            </div> : <Preloader />}
                    </VerticalTimelineElement>
                })}
            </VerticalTimeline>
            {/* <button className="mt-[200px] bold bg-[#ff0000] p-[10px] w-[300px] ml-[50%] translate-x-[-50%]" onClick={handleMoreSets}>
                Load more Sets
            </button> */}
        </div>
    )
}

export default Index