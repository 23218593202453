import ElementAnimation from "../Animations/ElementAnimation"

const SectionTitle = ({ title }) => {
    return (
        <ElementAnimation delay={.2}>
            <div className="mt-[50px] lg:mt-[0px] c-section-title-container flex h-48 items-center px-[40px] justify-center w-full text-center bg-[#007b4d12] rounded-lg mb-[50px]">
                <h2 className="c-section-title font-semibold uppercase text-neutral-500">
                    {title}
                </h2>
            </div>
        </ElementAnimation>

    )
}

export default SectionTitle