import { useState } from "react"
import { motion } from "framer-motion"
import SectionTitle from "../Titles/SectionTitle"

const Index = () => {

    const [formError, setFormError] = useState(false)

    const [fullName, setFullName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")

    const [fullNameError, setFullNameError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [subjectError, setSubjectError] = useState(false)
    const [messageError, setMessageError] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()

        const formData = new FormData()

        formData.append("name", fullName)
        formData.append("email", email)
        formData.append("phone", phone)
        formData.append("subject", subject)
        formData.append("message", message)

        // Check if any fields are empty
        if (
            !fullName ||
            !email ||
            !phone ||
            !subject ||
            !message
        ) {
            alert("Please fill out all fields")
            return
        }

        const response = await fetch("https://george-woofer.com/gwcontactform.php", {
            method: "POST",
            body: formData,
        })

        if (response.status === 200) {
            // Form submission was successful
            setFormError(true)
            setFullName("")
            setEmail("")
            setPhone("")
            setSubject("")
            setMessage("")
        } else {
            setFormError(false)
        }

        setFullNameError(!fullName)
        setEmailError(!email)
        setPhoneError(!phone)
        setSubjectError(!subject)
        setMessageError(!message)
    }


    return (
        <div className="c-contact-form max-w-[1200px] mx-auto overflow-hidden">
            <SectionTitle title="SAY HELLO" />
            <form onSubmit={handleSubmit}>

                <div className="form-group mt-[20px] mb-[20px]">
                    {fullNameError && <div className="block error">Full Name is required</div>}
                    <label htmlFor="fullName" className="block">Full Name</label>
                    <input
                        type="text"
                        className="w-[600px] py-4 rounded-lg"
                        id="fullName"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                    />
                </div>



                <div className="form-group mt-[20px] mb-[20px]">
                    {emailError && <div className="block error">Email is required</div>}
                    <label htmlFor="email" className="block">Email</label>
                    <input
                        type="email"
                        className="w-[600px] py-4 rounded-lg"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>


                <div className="form-group mt-[20px] mb-[20px]">
                    {phoneError && <div className="block error">Phone is required</div>}
                    <label htmlFor="phone" className="block">Phone</label>
                    <input
                        type="text"
                        className="w-[600px] py-4 rounded-lg"
                        id="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </div>



                <div className="form-group mt-[20px] mb-[20px]">
                    {subjectError && <div className="block error">Subject is required</div>}
                    <label htmlFor="email" className="block">Subject</label>
                    <input
                        type="text"
                        className="w-[600px] py-4 rounded-lg"
                        id="email"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                    />
                </div>



                <div className="form-group mt-[20px] mb-[20px]">
                    {messageError && <div className="block error">Message is required</div>}
                    <label htmlFor="message" className="block">Message</label>
                    <textarea
                        className="w-[600px] py-4 rounded-lg"
                        id="message"
                        rows="5"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                </div>



                <button initial={{ y: 50, opacity: 0 }} whileInView={{ y: 0, opacity: 1 }}
                    transition={{ duration: .3, delay: .6 }} viewport={{ once: true }} className="mt-[30px] mb-[20px] c-contact-form-submit-button c-btn-outline-v1" type="submit">Submit</button>

            </form>

            {formError ? (<h2 className="text-[20px] text-[green]">Form Submitted!</h2>) : ("")}
        </div>
    )
}

export default Index