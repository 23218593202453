import { useEffect } from "react";
import { motion } from "framer-motion";

const PageTransitionAnimation = ({ children }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <motion.div
            key="pagetransition"
            initial={{ x: 0, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: .3 }}
        >
            {children}
        </motion.div>
    )
}

export default PageTransitionAnimation